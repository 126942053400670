import React, { useContext, useEffect, useRef, useState, useLayoutEffect } from "react";
import { io } from "socket.io-client";
import {
  Box,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import MicIcon from "@material-ui/icons/Mic";

import { AuthContext } from "../../context/Auth/AuthContext";
import { useDate } from "../../hooks/useDate";
import api from "../../services/api";

const backendUrl = process.env.REACT_APP_BACKEND_URL || "http://localhost:4000"; // Fallback para desenvolvimento

const socket = io(backendUrl, {
  withCredentials: true, // Habilitar envio de cookies e autentica��o
});

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    overflow: "hidden",
    height: "100%",
    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: "#f0f2f5", // Fundo estilo WhatsApp
  },
  messageList: {
    position: "relative",
    overflowY: "auto",
    height: "100%",
    padding: "10px",
  },
  inputArea: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "#ffffff", // Fundo branco
    borderTop: "1px solid #ddd",
    gap: "10px",
  },
  input: {
    flex: 1, // Garante que o input ocupa toda a largura dispon�vel
    marginRight: "10px",
  },
  messageBox: {
    display: "flex",
    flexDirection: "column",
    margin: "5px 0",
  },
  sentMessage: {
    backgroundColor: "#d1f7c4", // Verde claro
    borderRadius: "8px 8px 0 8px",
    padding: "8px 12px",
    maxWidth: "60%",
    alignSelf: "flex-end", // Alinha � direita
    wordWrap: "break-word",
    textAlign: "right",
  },
  receivedMessage: {
    backgroundColor: "#ffffff", // Branco
    borderRadius: "8px 8px 8px 0",
    padding: "8px 12px",
    maxWidth: "60%",
    alignSelf: "flex-start", // Alinha � esquerda
    wordWrap: "break-word",
    textAlign: "left",
  },
  senderName: {
    fontSize: "0.8rem",
    fontWeight: "bold",
    marginBottom: "3px",
    color: "#555", // Cinza escuro
  },
  timestamp: {
    fontSize: "0.7rem",
    color: "#888", // Cinza claro
    marginTop: "3px",
  },
}));

export default function ChatMessages({
  chat,
  messages,
  handleLoadMore,
  scrollToBottomRef,
  pageInfo,
}) {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const { datetimeToClient } = useDate();
  const baseRef = useRef();
  const [contentMessage, setContentMessage] = useState("");
  const [localMessages, setLocalMessages] = useState(messages);

  const scrollToBottom = () => {
    console.log("baseRef.current:", baseRef.current);
    if (baseRef.current) {
      setTimeout(() => {
        baseRef.current.scrollIntoView({ behavior: "smooth" });
      }, 100); // Pequeno atraso
    }
  };
  

  useLayoutEffect(() => {
    scrollToBottom();
  }, [chat.id, messages]);
  
  
  // Atualiza mensagens no estado e rola para o final
  useEffect(() => {
    setLocalMessages(messages);
    scrollToBottom();
  }, [messages]);

  // Escuta novas mensagens via Socket.IO e atualiza
  useEffect(() => {
    socket.on(`company-${user.companyId}-chat-${chat.id}`, (newMessage) => {
      setLocalMessages((prevMessages) => [...prevMessages, newMessage]);
      scrollToBottom();
    });

    return () => {
      socket.off(`company-${user.companyId}-chat-${chat.id}`);
    };
  }, [chat.id, user.companyId]);

  const handleSendMessage = async (content) => {
    if (!content.trim()) return;
  
    try {
      await api.post(`/chats/${chat.id}/messages`, { message: content });
      setContentMessage(""); // Limpa o input
    } catch (err) {
      console.error("Erro ao enviar mensagem", err);
    }
  };
  

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
  
    const formData = new FormData();
    formData.append("media", file);
    formData.append("typeArch", "chat");
    formData.append("fileId", chat.id);
  
    try {
      await api.post(`/chats/${chat.id}/messages`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      scrollToBottom(); // Rola para o final ap�s envio
    } catch (err) {
      console.error("Erro ao enviar arquivo", err);
    }
  };
  

  return (
    <Paper className={classes.mainContainer}>
      <div className={classes.messageList}>
    {localMessages.map((item, key) => (
      <Box
        key={key}
        className={classes.messageBox}
        style={{
          alignItems: item.senderId === user.id ? "flex-end" : "flex-start",
        }}
      >
        {/* Nome do remetente */}
        <Typography className={classes.senderName}>
          {item.sender?.name || "Desconhecido"}
        </Typography>

        {/* Mensagem enviada ou recebida */}
        <div
          className={
            item.senderId === user.id
              ? classes.sentMessage
              : classes.receivedMessage
          }
        >
          {/* Exibir mensagem de texto */}
          {item.message && <Typography>{item.message}</Typography>}

          {/* Exibir bot�o de download se houver mediaPath */}
          {item.mediaPath && (
            <a
               href={item.mediaPath}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "inline-flex",
                alignItems: "center",
                textDecoration: "none",
                color: "#007BFF",
              }}
            >
              <AttachFileIcon style={{ marginRight: "5px" }} />
              <Typography
                variant="body2"
                style={{ textDecoration: "underline" }}
              >
                {item.mediaName || "Download"}
              </Typography>
            </a>
          )}
        </div>

      {/* Timestamp */}
      <Typography className={classes.timestamp}>
        {datetimeToClient(item.createdAt)}
      </Typography>
    </Box>
  ))}
  <div ref={baseRef}></div>
</div>

  
      {/* �rea de envio */}
      <div className={classes.inputArea}>
        <IconButton component="label">
          <AttachFileIcon />
          <input type="file" hidden onChange={handleFileUpload} />
        </IconButton>
        
        {/*
        <IconButton>
          <MicIcon />
        </IconButton>
        */}  
  
        <FormControl variant="outlined" className={classes.input}>
          <Input
            value={contentMessage}
            onChange={(e) => setContentMessage(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === "Enter" && contentMessage.trim() !== "") {
                handleSendMessage(contentMessage);
                setContentMessage("");
              }
            }}
            placeholder="Escreva sua mensagem..."
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    if (contentMessage.trim() !== "") {
                      handleSendMessage(contentMessage);
                      setContentMessage("");
                    }
                  }}
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
    </Paper>
  );
  
  
}
